import React, { useState, useEffect, Suspense } from "react"
import { useParams } from "react-router-dom"
import { useFirebase } from "../../providers/FirebaseContext"
import {
  CCol,
  CRow,
  CForm,
  CFormInput,
  CCard,
  CCardBody,
  CContainer,
  CButton,
  CCardHeader,
  CCardTitle,
  CSpinner,
} from "@coreui/react"
import FileExplore from "../../components/FileExplore"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
const Download = () => {
  const { getDownloadUrl, checkExpiryDate } = useFirebase()
  const { accessKey } = useParams()
  const [inputValue, setInputValue] = useState(accessKey)
  const [signedUrls, setSignedUrls] = useState(null)
  const [loading, setLoading] = useState(true)
  const [checkExpired, setCheckExpired] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const result = await checkExpiryDate(accessKey)
        console.log("Resault:", result)
        if (!result.data.status) {
          setErrorMessage(result.data.message)
          setCheckExpired(result.data.status)
        } else {
          setCheckExpired(result.data.status)
        }
      } catch (error) {
        console.log(error)
        setErrorMessage(error.message)
        setCheckExpired(false)
      }
      setLoading(false)
    }
    fetchData()
  }, [])

  // SecretKey登録時
  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()

    // Genarate Downlod Link
    const urls = await getDownloadUrl(inputValue)
    console.log(urls)
    setSignedUrls(urls)
    setLoading(false)
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // この値はビューポートの高さに基づいています
      }}
    >
      {loading ? (
        <CSpinner color="primary" variant="grow" />
      ) : (
        <Suspense fallback={<CSpinner color="primary" variant="grow" />}>
          <CContainer>
            <CRow className="row justify-content-center">
              <CCol xs={12} sm={10} md={8} lg={7} xl={6} xxl={6}>
                <CCard>
                  <CCardHeader color="primary">
                    <CCardTitle className="mb-0 fs-6 text-center">
                      <small>
                        <div
                          style={{
                            fontFamily: 'fot-udkakugo-large-pr6n", sans-serif',
                            fontStyle: "normal",
                            fontWeight: "400",
                          }}
                        >
                          <FontAwesomeIcon icon={("fad", "file")} />
                          スクリュー精機ファイル共有サービス
                        </div>
                      </small>
                    </CCardTitle>
                  </CCardHeader>
                  <CCardBody>
                    {checkExpired ? (
                      signedUrls === null ? (
                        <CForm>
                          <CFormInput
                            size="sm"
                            type="text"
                            id="floatingInput"
                            floatingClassName="mb-3"
                            floatingLabel="アクセスキー"
                            value={inputValue}
                            onChange={(event) => {
                              setInputValue(event.target.value)
                            }}
                            style={{
                              fontFamily:
                                'fot-udkakugo-large-pr6n", sans-serif',
                              fontStyle: "normal",
                              fontWeight: "400",
                            }}
                          />
                          <div className="d-flex justify-content-end">
                            <CButton
                              size="sm"
                              onClick={handleSubmit}
                              type="button"
                            >
                              <div
                                style={{
                                  fontFamily:
                                    'fot-udkakugo-large-pr6n", sans-serif',
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                }}
                              >
                                <FontAwesomeIcon icon={("fad", "key")} />
                                認証
                              </div>
                            </CButton>
                          </div>
                        </CForm>
                      ) : (
                        <FileExplore signedUrls={signedUrls} />
                      )
                    ) : (
                      <p>{errorMessage}</p>
                    )}
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </CContainer>
        </Suspense>
      )}
    </div>
  )
}

export default Download
