import React from "react"
import {
  CContainer,
  CRow,
  CCol,
  CListGroup,
  CListGroupItem,
} from "@coreui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFile,
  faFileVideo,
  faFilePdf,
  faFileWord,
  faFileZip,
  faFilePowerpoint,
  faFileImage,
  faFileExcel,
  faFileMusic,
  faFileCode,
  faCube,
} from "@fortawesome/pro-regular-svg-icons"
const handleDownload = (url, filename) => {
  const link = document.createElement("a")
  link.href = url
  link.download = filename || "download"
  link.style.display = "none"
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const alalyzeUrl = (url) => {
  const pattern =
    /https:\/\/storage\.googleapis\.com\/([^/]+)\/([^/]+)\/([^/]+)\/([^\.]+)(\.[^?]+)(\?.*)/
  const match = url.match(pattern)
  return {
    fileName: decodeURIComponent(match[4]),
    ext: decodeURIComponent(match[5]),
  }
}
const renderIcon = (ext) => {
  switch (ext) {
    case ".mp4":
      return <FontAwesomeIcon icon={faFileVideo} />
    case ".mp3":
      return <FontAwesomeIcon icon={faFileMusic} />
    case ".docx":
    case ".doc":
      return <FontAwesomeIcon icon={faFileWord} />
    case ".pptx":
    case ".ppt":
      return <FontAwesomeIcon icon={faFilePowerpoint} />
    case ".xlsx":
    case ".xls":
      return <FontAwesomeIcon icon={faFileExcel} />
    case ".pdf":
      return <FontAwesomeIcon icon={faFilePdf} />
    case ".SLDPRT":
    case ".SLDASM":
    case ".SLDDRW":
    case ".SLDPRP":
    case ".sldmat":
    case ".DWG":
    case ".DXF":
    case ".STP":
    case ".STEP":
    case ".IGS":
    case ".IGES":
    case ".CATPART":
    case ".CATPRODUCT":
    case ".IPT":
    case ".IAM":
    case ".3DM":
    case ".STL":
    case ".OBJ":
    case ".FBX":
      return <FontAwesomeIcon icon={faCube} />
    case ".jpg":
    case ".jpeg":
    case ".bmp":
    case ".png":
    case ".tiff":
      return <FontAwesomeIcon icon={faFileImage} />
    case ".zip":
      return <FontAwesomeIcon icon={faFileZip} />
    case ".html":
      return <FontAwesomeIcon icon={faFileCode} />
    default:
      return <FontAwesomeIcon icon={faFile} />
  }
}

const FileExplore = ({ signedUrls }) => {
  return (
    <CContainer>
      <CRow>
        <CCol>
          <p className="fs-6">
            <small>クリックするとダウンロードが開始します</small>
          </p>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CListGroup>
            {signedUrls &&
              signedUrls.map((url) => {
                const fileObj = alalyzeUrl(url)
                return (
                  <CListGroupItem
                    component="a"
                    style={{ cursor: "pointer" }}
                    key={fileObj.fileName}
                    onClick={() =>
                      handleDownload(url, `${fileObj.fileName}${fileObj.ext}`)
                    }
                  >
                    {renderIcon(fileObj.ext)}&nbsp;
                    {`${fileObj.fileName}${fileObj.ext}`}
                  </CListGroupItem>
                )
              })}
          </CListGroup>
        </CCol>
      </CRow>
    </CContainer>
  )
}
export default FileExplore
/*
 

https://storage.googleapis.com/screwseiki.appspot.com/files/u93frgF0MJ3PIDtGAXAI/%E3%83%A9%E3%82%A4%E3%83%96%E3%80%8CJ%E3%80%8D.mp4?GoogleAccessId=screwseiki%40appspot.gserviceaccount.com&Expires=1699578202&Signature=lbIFX94lzF1olZatiF4EAL4f8TwdqltwZUc%2B%2B8Jh0KLfMb%2BqP1OKDtR48vrAAs1lkcdlHDxx2mvuJbyBfJJCgcKdYdj1LWiwI%2B%2FlpTMaxqj5UeQ2i%2FPuTNXdf4EVAP1MJO%2FqrVJU%2B2rxcF1PDSEBV9vBibcOLlFrjScfBwuSyf80Lexr51bAleJulR7rLfwzxZWMR7z9bbrMCMcMbW1r0hQ0OiCytJ16Syp2dpJrtGF49gADPnnZ8NhYSnhaidPBZGKFjaEn3okxpZDcZHoGYHHZMl5sxlWZoCGJXUaqznvSLvklYFBqRTzup7sbqGK4VA1uKWTqbZ5g7OM4IHLaDA%3D%3D
https://storage.googleapis.com/
screwseiki.appspot.com/
files/
u93frgF0MJ3PIDtGAXAI/
%E3%83%A9%E3%82%A4%E3%83%96%E3%80%8CJ%E3%80%8D.mp4
?GoogleAccessId=screwseiki%40appspot.gserviceaccount.com&Expires=1699578202&Signature=lbIFX94lzF1olZatiF4EAL4f8TwdqltwZUc%2B%2B8Jh0KLfMb%2BqP1OKDtR48vrAAs1lkcdlHDxx2mvuJbyBfJJCgcKdYdj1LWiwI%2B%2FlpTMaxqj5UeQ2i%2FPuTNXdf4EVAP1MJO%2FqrVJU%2B2rxcF1PDSEBV9vBibcOLlFrjScfBwuSyf80Lexr51bAleJulR7rLfwzxZWMR7z9bbrMCMcMbW1r0hQ0OiCytJ16Syp2dpJrtGF49gADPnnZ8NhYSnhaidPBZGKFjaEn3okxpZDcZHoGYHHZMl5sxlWZoCGJXUaqznvSLvklYFBqRTzup7sbqGK4VA1uKWTqbZ5g7OM4IHLaDA%3D%3D

 
 */
