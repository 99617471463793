import React from "react"
import { CContainer, CRow, CCol } from "@coreui/react"
const Page404 = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // この値はビューポートの高さに基づいています
      }}
    >
      <CContainer>
        <CRow className="row justify-content-center">
          <CCol
            xs={12}
            sm={10}
            md={6}
            lg={5}
            xl={4}
            xxl={3}
            className="text-center"
          >
            <div
              style={{
                fontFamily: '"forma-djr-display", sans-serif',
                fontWeight: "700",
                fontStyle: "normal",
              }}
            ></div>
            Access Denided.
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}
export default Page404
