import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Download from "./views/download/Download"
import Page404 from "./pages/Page404"
import { FirebaseProvider } from "./providers/FirebaseContext"
import './App.css'
function App() {
  return (
    <FirebaseProvider>
      <Router>
        <Routes>
          <Route path="/:accessKey" element={<Download />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Router>
    </FirebaseProvider>
  )
}

export default App
