import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getStorage } from "firebase/storage"
import { getFirestore } from "firebase/firestore"
import { getFunctions } from "firebase/functions"
const firebaseConfig = {
  apiKey: "AIzaSyDYq8piJHf0cUPveCN1yVvKDbAJDF-1I_s",
  authDomain: "screwseiki.firebaseapp.com",
  projectId: "screwseiki",
  storageBucket: "screwseiki.appspot.com",
  messagingSenderId: "408970748761",
  appId: "1:408970748761:web:839321b9f7a79d92f2e248",
}
const app = initializeApp(firebaseConfig)

const auth = getAuth(app)
const firestore = getFirestore(app)
const storage = getStorage(app)
const functions = getFunctions(app, "asia-northeast1")

export { auth, firestore, storage, functions }
