import React, { createContext, useContext, useState } from "react"
import { firestore, storage, functions } from "../firebaseConfig"
import { doc, getDoc, onSnapshot, serverTimestamp } from "firebase/firestore"
import { httpsCallable } from "firebase/functions"

const FirebaseContext = createContext()

export const useFirebase = () => {
  return useContext(FirebaseContext)
}

const fetchProgress = (accessKey, callback) => {
  try {
    const docRef = doc(firestore, `progress/${accessKey}`)
    return onSnapshot(docRef, (doc) => {
      const progress = doc.data()?.progress
      callback(progress)
    })
  } catch (error) {
    throw error
  }
}

const firestoreGetDocs = async (accessKey) => {
  try {
    const docRef = doc(firestore, "shares", accessKey)
    const docSnap = await getDoc(docRef)
    return docSnap.data()
  } catch (error) {
    throw error
  }
}

const getDownloadUrl = async (inputValue) => {
  const zipFunction = httpsCallable(functions, "provideDownloadLink")
  try {
    const result = await zipFunction({
      secretKey: inputValue,
    })
    return result.data.signedUrls
  } catch (error) {
    throw error
  }
}

const checkExpiryDate = async (docId) => {
  const checkExpiry = httpsCallable(functions, "checkExpiryDate")
  try {
    const result = await checkExpiry({ docId: docId })
    console.log("result1 : ", result)
    return result // 関数から直接値を返す
  } catch (error) {
    throw error
  }
}

// const checkExpiryDate = firebase.functions().httpsCallable('checkExpiryDate');
// checkExpiryDate({ documentId: 'yourDocumentId' })
//   .then((result) => {
//     console.log('Is expired:', result.data); // true または false
//   })
//   .catch((error) => {
//     console.error('Error:', error);
//   });

export const FirebaseProvider = ({ children }) => {
  const contextValue = {
    firestore,
    storage,
    serverTimestamp,
    firestoreGetDocs,
    getDownloadUrl,
    fetchProgress,
    checkExpiryDate,
  }

  return (
    <FirebaseContext.Provider value={contextValue}>
      {children}
    </FirebaseContext.Provider>
  )
}
